<template>
  <section>
    <validation-observer
      #default="{ handleSubmit, invalid, pristine }"
      ref="refFormObserver"
    >
      <!-- SECTION Form -->
      <b-form
        @submit.prevent="handleSubmit(submitHandle)"
      >
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <!-- ANCHOR header -->
          <template #header>
            <h6 class="m-0">
              {{ $t('promotionAirline.create.title') }}
            </h6>
          </template>
          <b-card-body class="p-0">
            <b-row class="mt-1 mb-3">
              <!-- ANCHOR Code -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAirline.fields.code')"
                  rules="required|code|max:100"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.code') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.code"
                      maxlength="100"
                      minlength="1"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('promotionAirline.placeholder.code')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Airline -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAirline.fields.airline')"
                  rules="required"
                >
                  <b-form-group
                    class="mb-0"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.airline') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToAdd.airline"
                      style="font-size: 1rem;"
                      :options="airlineFilterOptions"
                      :clearable="false"
                      :reduce="val => val.value"
                      :placeholder="$t('promotionAirline.placeholder.airline')"
                      @close="validationContext.validate"
                    >
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template v-slot:option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class=" d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

            </b-row>

            <b-row class="mb-2">
              <!-- ANCHOR Start time -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAirline.fields.startTime')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.startTime') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToAdd.startTime"
                      type="date"
                      :locale="$i18n.locale"
                      :placeholder="$t('promotionAirline.placeholder.startTime')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="new Date()"
                      :max="promotionDataToAdd.endTime ? new Date(promotionDataToAdd.endTime) : null"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      flip
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR End date -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAirline.fields.endTime')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.endTime') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToAdd.endTime"
                      type="date"
                      :locale="$i18n.locale"
                      :placeholder="$t('promotionAirline.placeholder.endTime')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="promotionDataToAdd.startTime ? new Date(promotionDataToAdd.startTime) : new Date()"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      flip
                      :state="getValidationState(validationContext) === false ? false : null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR priority -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAirline.fields.priority')"
                  rules="max:5"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.priority') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.priority"
                      v-remove-non-numeric-chars.allNumber
                      number
                      maxlength="2"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAirline.placeholder.priority')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <!-- ANCHOR Flight start date -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAirline.fields.flightStartTime') }}
                    </div>
                  </template>
                  <b-form-datepicker
                    v-model="promotionDataToAdd.flightStartTime"
                    type="date"
                    :locale="$i18n.locale"
                    :placeholder="$t('promotionAirline.placeholder.flightStartTime')"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="new Date()"
                    :max="promotionDataToAdd.flightEndTime ? new Date(promotionDataToAdd.flightEndTime) : null"
                    show-decade-nav
                    hide-header
                    boundary="window"
                    flip
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR Flight end date -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAirline.fields.flightEndTime') }}
                    </div>
                  </template>
                  <b-form-datepicker
                    v-model="promotionDataToAdd.flightEndTime"
                    type="date"
                    :locale="$i18n.locale"
                    :placeholder="$t('promotionAirline.placeholder.flightEndTime')"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="promotionDataToAdd.flightStartTime ? new Date(promotionDataToAdd.flightStartTime) : new Date()"
                    show-decade-nav
                    hide-header
                    boundary="window"
                    flip
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR minimum Passenger -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAirline.fields.minimumPassenger')"
                  rules="max:5"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.minimumPassenger') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.minimumPassenger"
                      v-remove-non-numeric-chars.allNumber
                      number
                      maxlength="5"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAirline.placeholder.minimumPassenger')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mb-0">
              <!-- ANCHOR description -->
              <b-col
                cols="12"
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAirline.fields.description') }}
                    </div>
                  </template>
                  <b-form-textarea
                    v-model="promotionDataToAdd.description"
                    rows="3"
                    :placeholder="$t('promotionAirline.placeholder.description')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- ANCHOR Action Buttons -->
        <div class="d-flex mt-1 justify-content-center">
          <!-- Back button -->
          <b-button
            variant="secondary"
            class="mr-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="backHandle"
          >
            {{ $t('back') }}
          </b-button>

          <!-- Clear button -->
          <b-button
            variant="danger"
            class="mx-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="pristine"
            @click="clearHandle"
          >
            {{ $t('clear') }}
          </b-button>

          <!-- Next button -->
          <b-button
            variant="info"
            class="ml-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="invalid"
            type="submit"
          >
            {{ $t('save') }}
          </b-button>
        </div>
      </b-form>
      <!-- !SECTION -->
    </validation-observer>
  </section>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import usePromotionAirlineHandle from '@promotionAirline/usePromotionAirlineHandle'
import promotionAirlineStoreModule from '@promotionAirline/promotionAirlineStoreModule'
import usePromotionAgAirlines from '@promotionAg/usePromotionAgAirlines'
import {
  max, min, required, code,
} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const PROMOTION_APP_STORE_MODULE_NAME = 'app-promotionAirline'

    // Register module
    if (!store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_APP_STORE_MODULE_NAME, promotionAirlineStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_APP_STORE_MODULE_NAME)
      }
    })

    const blankPromotionData = {
      code: '',
      airline: null,
      startTime: null,
      endTime: null,
      flightStartTime: null,
      flightEndTime: null,
      minimumPassenger: null,
      priority: null,
      description: null,
    }
    const promotionDataToAdd = ref(JSON.parse(JSON.stringify(blankPromotionData)))
    const resetPromotionData = () => {
      promotionDataToAdd.value = JSON.parse(JSON.stringify(blankPromotionData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPromotionData)
    const {
      createPromotionAirline,
      loading,
    } = usePromotionAirlineHandle()

    function submitHandle() {
      this.$bvModal.show('modal-api-loading')
      createPromotionAirline(promotionDataToAdd.value).then(() => {
        this.$router.push({ name: 'apps-promotionAirline-list' })
      }).finally(() => {
        this.$bvModal.hide('modal-api-loading')
      })
    }

    function backHandle() {
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    const {
      airlineFilterOptions,
    } = usePromotionAgAirlines()

    return {
      promotionDataToAdd,
      refFormObserver,
      getValidationState,

      loading,

      // Validations
      required,
      min,
      max,
      code,

      // Methods
      backHandle,
      submitHandle,
      clearHandle,
      trimInput,
      upperCaseFormatter,

      /* returned by usePromotionAgAirlines() */
      airlineFilterOptions,
      /* !returned by usePromotionAgAirlines() */
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
